<template>
  <div class="fileManagement">
    <!-- 档案管理 -->
    <div class="searchBtn">
      <el-select v-model="searchInfo.sex" placeholder="请选择性别" size="small">
        <el-option label="全部" :value="4">
        </el-option>
        <el-option label="男" :value="1">
        </el-option>
        <el-option label="女" :value="2">
        </el-option>
        <el-option label="未填写" :value="3">
        </el-option>
      </el-select>
      <el-cascader :options="OrganizationsList" v-model="searchInfo.deptId" size="small" placeholder="请选择组织机构"
        :show-all-levels="false" :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false }">
      </el-cascader>
      <!-- <el-cascader :options="RolesList" placeholder="请选择角色" v-model="searchInfo.roleId" size="small"
        :props="{ value: 'id', label: 'name', emitPath: false }" :show-all-levels="false"></el-cascader> -->
      <el-input placeholder="开始年龄" v-model="searchInfo.startAge" type="number" size="small" clearable
        oninput="value=value.replace(/[^0-9.]/g,'')" style="margin-right:10px"></el-input>
      <span>至</span>
      <el-input placeholder="结束年龄" v-model="searchInfo.endAge" type="number" size="small" clearable
        oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
      <el-date-picker size="small" v-model="searchInfo.startTime" type="date" placeholder="开始时间"
        value-format="yyyy-MM-dd 00:00:00" style="margin-right:10px" format="yyyy-MM-dd">
      </el-date-picker>
      <span>至</span>
      <el-date-picker size="small" v-model="searchInfo.endTime" type="date" placeholder="结束时间" format="yyyy-MM-dd"
        value-format="yyyy-MM-dd 23:59:59">
      </el-date-picker>
      <el-input placeholder="请输入姓名" v-model="searchInfo.name" size="small" clearable></el-input>
      <el-button type="primary" size="small" @click="search">搜索</el-button>
    </div>
    <div class="tableContent">
      <el-button type="primary" size="small" @click="downloadData" style="margin-bottom:20px">导出数据</el-button>
      <el-button type="primary" size="small" @click="downloadWord" style="margin-bottom:20px">导出报告</el-button>
      <template>
        <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection"></el-table-column>
          <el-table-column prop="id" label="用户编号" width="100"></el-table-column>
          <el-table-column prop="nickname" label="姓名" width="180" show-overflow-tooltip></el-table-column>
          <el-table-column label="性别" width="80">
            <template slot-scope="scope">
              <span v-if="scope.row.sex === 1">男</span>
              <span v-else-if="scope.row.sex === 2">女</span>
              <span v-else-if="scope.row.sex === 3">未填写</span>
            </template>
          </el-table-column>
          <el-table-column prop="birthday" label="出生日期" width="150" show-overflow-tooltip>
          </el-table-column>
          <!-- <el-table-column prop="province" label="UID">
          </el-table-column> -->
          <el-table-column prop="age" label="年龄" width="100" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="username" label="登录名" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="departmentName" label="组织机构" show-overflow-tooltip>
          </el-table-column>
          <!-- <el-table-column prop="roleName" label="用户角色" width="180" show-overflow-tooltip>
          </el-table-column> -->
          <el-table-column prop="source" label="来源" width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.source === 1"> 后台新增</span>
              <span v-if="scope.row.source === 2"> 用户注册</span>
              <span v-if="scope.row.source === 3"> 后台导入</span>
              <span v-if="scope.row.source === 4"> 游客</span>
              <span v-if="scope.row.source === 5"> 微信登录</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="禁用" width="80">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.status" @change="changeStatus(scope.row)" :active-value="1"
                :inactive-value="0">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="170">
          </el-table-column>

          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <!-- <el-button type="text" size="small" @click="openDetail(scope.row.id)">查看</el-button> -->
              <el-button type="text" size="small" @click="openDetail(scope.row.id)">健康档案</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <div class="block">
      <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
        layout="total, prev, pager, next, jumper" :total="paging.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getFileList } from '@/api/healthRecords/filemanagement'
import { getOrganizationList } from '@/api/system/organization';
import { getRoleList } from '@/api/system/role'
import { updateUserStatus } from '@/api/system/users'
export default {
  data() {
    return {
      searchInfo: {
        deptId: null,
        sex: null,
        startAge: null,
        endAge: null,
        startTime: null,
        endTime: null,
        name: null

      }, // 搜索信息
      paging: {
        p: 1,
        ps: 18,
        total: 0
      }, // 分页信息
      OrganizationsList: [], // 组织机构列表
      RolesList: [], // 角色列表
      tableData: [], // 表格数据
      selectList: [], // 用户选择的数据
    }
  },
  methods: {
    // 获取组织机构列表
    async getOrganizationsList() {
      const res = await getOrganizationList()
      // console.log('组织机构');
      console.log(res);
      if (res.code === 200) {
        this.OrganizationsList = res.data;
        this.OrganizationsList = [{ id: -1, name: '全部' }, ...this.OrganizationsList]
        // this.deleteNullChildren(this.OrganizationsList)
      }
    },
    // 获取角色列表
    async getRolesList() {
      const res = await getRoleList();
      console.log(res);
      if (res.code === 200) {
        this.RolesList = res.data;
        this.RolesList = [{ id: -1, name: '全部' }, ...this.RolesList]
      }
    },
    // 获取档案管理列表
    async getManagementList() {
      let info = {...this.searchInfo};
      if(info.sex === 4) {
        info.sex = null
      }
      if(info.deptId === -1) {
        info.deptId = null
      }
      const res = await getFileList(this.paging, JSON.stringify(info));
      console.log(res);
      if (res.code === 200) {
        this.tableData = res.data.records;
        this.paging.total = res.data.total;
      }
    },
    // 修改用户的状态
    async changeStatus(val) {
      console.log(val);
      const info = new FormData()
      info.append("id", val.id);
      info.append("status", val.status);
      console.log(info);
      const res = await updateUserStatus(info);
      console.log(res);
      if (res.code === 200) {
        this.$message({
          showClose: true,
          message: '修改成功！',
          type: 'success'
        });
        this.getManagementList();
      } else {
        this.$message({
          showClose: true,
          message: '修改失败！' + res.data,
          type: 'error'
        });
      }
    },
    // 导出数据
    async downloadData() {
      if (this.selectList.length > 0) {
        let arr = [];
        this.selectList.forEach(item => {
          arr.push(item.id);
        });
        let list = arr.join(',');
        const elink = document.createElement('a');
        elink.href = `${this.$store.state.requestApi}/archives/user/export?ids=${list}`;
        // elink.href = `http://192.168.31.135:8089/api/archives/user/export?ids=${list}`;
        elink.click();
      } else {
         this.$message({
          showClose: true,
          message: '请选择需要导出的数据！'
        });
      }
    },
    // 导出报告
    async downloadWord() {
      if (this.selectList.length > 0) {
        let arr = [];
        this.selectList.forEach(item => {
          arr.push(item.id);
        });
        let list = arr.join(',');
        const elink = document.createElement('a');
        elink.href = `${this.$store.state.requestApi}/archives/users/report/export?ids=${list}`;
        // elink.href = `http://192.168.0.107:8089/api/archives/users/report/export?ids=${list}`;
        elink.click();
      } else {
         this.$message({
          showClose: true,
          message: '请选择需要导出的数据！'
        });
      }
    },
    // 用户点击选择的选项
    handleSelectionChange(val) {
      this.selectList = val;
    },
    // 搜索
    search() {
      this.getManagementList();
    },
    // 切换分页
    handleCurrentChange(val) {
      this.paging.p = val;
      this.getManagementList()
    },
    // 点击查看健康档案
    openDetail(val) {
      this.$router.push('/home/healthRecords/userInfo')
      sessionStorage.setItem('fileUserId', val)
    }

  },
  created() {
    this.getManagementList();
    this.getOrganizationsList();
    // this.getRolesList();
  }
}
</script>

<style lang="scss" scoped>
.fileManagement {
  padding: 20px;

  .searchBtn {
    ::v-deep.el-date-editor {
      width: 150px !important;
    }

    span {
      display: inline-block;
      margin-right: 10px;
      line-height: 32px;
    }
  }

  .tableContent {
    margin-top: 20px;
  }
}
</style>